<template>
  <v-sheet class="pa-4 mt-4" border>
    <v-overlay :model-value="isSubmitting" class="align-center justify-center">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-form @submit.prevent="submit" :disabled="isSubmitting">
      <v-text-field
        v-model="first_name.value.value"
        label="Imię kandydata/tki"
        variant="underlined"
        color="primary"
        :error-messages="errors.first_name"
        data-test="first_name"
      ></v-text-field>

      <v-text-field
        v-model="last_name.value.value"
        label="Nazwisko kandydata/tki"
        variant="underlined"
        color="primary"
        :error-messages="errors.last_name"
        data-test="last_name"
      ></v-text-field>

      <!--
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">

      <v-text-field
        type="date"
        v-model="birth_date.value.value"
        label="Data urodzenia kandydata/tki"
        variant="underlined"
        color="primary"
        :error-messages="errors.birth_date"
        data-test="birth_date"
      ></v-text-field>

      <v-date-picker
      ref="picker"
        v-model="birth_date.value.value"
        min="2004-01-01"
        max="2010-12-31"
        first-day-of-week="1"
        locale="pl"
        @input="menu = false"
        @change="save"
      ></v-date-picker>
-->
      <v-row>
        <v-col cols="12" sm="4">
          <div class="text-caption">Data urodzenia kandydata/tki</div>
          <VueDatePicker
            v-model="birth_date.value.value"
            model-type="yyyy-MM-dd"
            :enable-time-picker="false"
            auto-apply
            placeholder="Podaj datę"
            format="yyyy-MM-dd"
            locale="pl"
            start-date="2011-01-01"
            min-date="2009-01-01"
            prevent-min-max-navigation
            position="left"
            data-test="birth_date"
            input-class-name="dp-custom-input"
          ></VueDatePicker>
          <div class="text-caption text-error pt-1">{{ errors.birth_date }}</div>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="pesel.value.value"
            label="Pesel kandydata/tki"
            variant="underlined"
            color="primary"
            :error-messages="errors.pesel"
            :disabled="noPesel"
            data-test="pesel"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="4">
          <v-switch v-model="noPesel" label="Kandydat/tka nie ma Peselu" inset @change="changePeselSwitch" data-test="noPesel"> </v-switch>
        </v-col>
      </v-row>

      <v-select
        v-model="school_level.value.value"
        label="Klasa kandydata/tki"
        :items="schoolLevels"
        variant="underlined"
        color="primary"
        :error-messages="errors.school_level"
        data-test="school_level"
      ></v-select>

      <v-text-field
        v-model="email.value.value"
        label="E-mail do komunikacji"
        variant="underlined"
        color="primary"
        :error-messages="errors.email"
        data-test="email"
      ></v-text-field>

      <v-text-field
        v-model="password.value.value"
        label="Hasło do logowania"
        variant="underlined"
        color="primary"
        :append-icon="passwordHidden ? 'mdi-eye-off' : 'mdi-eye'"
        @click:append="() => (passwordHidden = !passwordHidden)"
        :type="passwordHidden ? 'password' : 'text'"
        :error-messages="errors.password"
        data-test="password"
      ></v-text-field>

      <v-text-field
        v-model="password_confirmation.value.value"
        label="Powtórzenie hasła"
        variant="underlined"
        color="primary"
        :type="passwordHidden ? 'password' : 'text'"
        :error-messages="errors.password_confirmation"
        data-test="password_confirmation"
      ></v-text-field>

      <v-divider class="mt-4 mb-4"></v-divider>

      <p><strong>Prosimy również o wypełnienie krótkiej ankiety.</strong></p>

      <p>1. Skąd pozyskali Państwo informacje o Bednarskiej Szkole Realnej?</p>
      <p class="text-body-2">(Można wybrać kilka odpowiedzi)</p>
      <v-card-text class="pt-2">
        <v-checkbox
          v-model="q1_1.value.value"
          density
          hide-details
          false-value="0"
          true-value="1"
          label="Znajomi/rodzina uczęszczają bądź uczęszczali do BSR"
          data-test="q1_1"
        ></v-checkbox>
        <v-checkbox
          v-model="q1_2.value.value"
          density
          hide-details
          false-value="0"
          true-value="1"
          label="Spotkaliśmy przedstawicieli szkoły na Targach Szkół Niepublicznych"
          data-test="q1_2"
        />
        <v-checkbox
          v-model="q1_3.value.value"
          density
          hide-details
          false-value="0"
          true-value="1"
          label="Zobaczyliśmy informację na Facebooku/Instagramie"
          data-test="q1_3"
        ></v-checkbox>
        <v-checkbox v-model="q1_4.value.value" density hide-details false-value="0" true-value="1" label="Inne źródła" data-test="q1_4"></v-checkbox>
        <v-text-field
          v-if="q1_4.value.value == 1"
          v-model="q1_4_1.value.value"
          :error-messages="errors.q1_4_1"
          class="ml-6 mt-2"
          max-width="75%"
          density
          variant="underlined"
          label="jakie?"
          data-test="q1_4_1"
        ></v-text-field>
        <div v-if="errors.q1_1" class="text-red-darken-4">{{ errors.q1_1 }}</div>
      </v-card-text>

      <p>2. Co Państwa przekonało do wzięcia udziału w rekrutacji do BSR?</p>
      <p class="text-body-2">(Można wybrać kilka odpowiedzi)</p>
      <v-card-text class="pt-2">
        <v-checkbox
          v-model="q2_1.value.value"
          density
          hide-details
          false-value="0"
          true-value="1"
          label="Opinia znajomych/ rodziny"
          data-test="q2_1"
        ></v-checkbox>
        <v-checkbox
          v-model="q2_2.value.value"
          density
          hide-details
          false-value="0"
          true-value="1"
          label="Wizyta na Dniu Otwartym"
          data-test="q2_2"
        ></v-checkbox>
        <v-checkbox
          v-model="q2_3.value.value"
          density
          hide-details
          false-value="0"
          true-value="1"
          label="Udział w Kursie 100% w Realu"
          data-test="q2_3"
        ></v-checkbox>
        <v-checkbox v-model="q2_4.value.value" density hide-details false-value="0" true-value="1" label="Inne" data-test="q2_4"></v-checkbox>
        <v-text-field
          v-if="q2_4.value.value == 1"
          v-model="q2_4_1.value.value"
          :error-messages="errors.q2_4_1"
          class="ml-6 mt-2"
          max-width="75%"
          density
          variant="underlined"
          label="jakie?"
          data-test="q2_4_1"
        ></v-text-field>
        <div v-if="errors.q2_1" class="text-red-darken-4">{{ errors.q2_1 }}</div>
      </v-card-text>

      <v-divider class="mt-2"></v-divider>

      <v-card-text class="pl-0 title">Wypełnia rodzic/opiekun kandydata/tki:</v-card-text>

      <p>
        <strong>Bardzo prosimy uważnie zapoznać się z poniższymi dokumentami przed zakończeniem procesu rejestracji.</strong>
      </p>

      <v-dialog v-model="dialog_rules1" max-width="750px">
        <template v-slot:activator="{ props }">
          <v-btn variant="text" small color="primary" left v-bind="props" class="pl-0">
            > Zasady rekrutacji do Bednarskiej Szkoły Realnej w roku 2025
          </v-btn>
        </template>
        <template v-slot:default="{ isActive }">
          <register-rules @dialog="dialog_rules1 = $event"></register-rules>
        </template>
      </v-dialog>

      <br />
      <v-dialog v-model="dialog_rodo1" max-width="750px">
        <template v-slot:activator="{ props }">
          <v-btn variant="text" small color="primary" left v-bind="props" class="pl-0"> > Klauzula informacyjna RODO </v-btn>
        </template>
        <register-rodo @dialog="dialog_rodo1 = $event"></register-rodo>
      </v-dialog>

      <v-checkbox
        v-model="isConfirmation1.value.value"
        label="Zapoznałem/am się z zasadami rekrutacji oraz klauzulą informacyjną RODO"
        :error-messages="errors.isConfirmation1"
        data-test="isConfirmation1"
      ></v-checkbox>

      <v-card-text class="pl-0 title">Wypełnia kandydat/tka:</v-card-text>

      <p>
        <strong>Bardzo prosimy uważnie zapoznać się z poniższymi dokumentami przed zakończeniem procesu rejestracji.</strong>
      </p>
      <v-dialog v-model="dialog_rules2" max-width="750px">
        <template v-slot:activator="{ props }">
          <v-btn variant="text" small color="primary" left v-bind="props" class="pl-0">
            > Zasady rekrutacji do Bednarskiej Szkoły Realnej w roku 2025
          </v-btn>
        </template>
        <register-rules @dialog="dialog_rules2 = $event"></register-rules>
      </v-dialog>
      <br />
      <v-dialog v-model="dialog_rodo2" max-width="750px">
        <template v-slot:activator="{ props }">
          <v-btn variant="text" small color="primary" left v-bind="props" class="pl-0"> > Klauzula informacyjna RODO </v-btn>
        </template>
        <register-rodo @dialog="dialog_rodo2 = $event"></register-rodo>
      </v-dialog>

      <v-checkbox
        v-model="isConfirmation2.value.value"
        label="Zapoznałem/am się z zasadami rekrutacji oraz klauzulą informacyjną RODO"
        :error-messages="errors.isConfirmation2"
        data-test="isConfirmation2"
      ></v-checkbox>

      <btn-cancel :loading="isSubmitting" :disabled="isSubmitting" to="/">Rezygnuję z rejestracji</btn-cancel>
      <btn-submit :loading="isSubmitting" :disabled="isSubmitting" class="ml-4">Zarejestruj</btn-submit>
    </v-form>
  </v-sheet>
</template>

<script setup>
import BtnSubmit from '@/components/shared/UI/BtnSubmit.vue'
import BtnCancel from '@/components/shared/UI/BtnCancel.vue'

import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

import RegisterRules from '@/components/shared/RegisterRules.vue'
import RegisterRodo from '@/components/shared/RegisterRodo.vue'

import { ref } from 'vue'
import { useField, useForm } from 'vee-validate'
import { useToast } from 'vue-toast-notification'

import axios from 'axios'
import { api } from '@/config'

const validationSchema = {
  first_name: 'required|min:3|name',
  last_name: 'required|min:3|name',
  birth_date: 'required|min:3',
  pesel: 'pesel',
  school_level: (value) => !!value || 'Podanie klasy jest wymagane',
  email: 'required|min:3',
  password: 'required|min:6',
  password_confirmation: 'required|min:6',
  isConfirmation1: (value) => value || 'Proszę potwierdzić',
  isConfirmation2: (value) => value || 'Proszę potwierdzić',

  //ankieta:
  q1_1: null,
  q1_2: null,
  q1_3: null,
  q1_4: null,
  q1_4_1: null,
  q2_1: null,
  q2_2: null,
  q2_3: null,
  q2_4: null,
  q2_4_1: null
}

const { errors, setErrors, handleSubmit, isSubmitting } = useForm({
  validationSchema: validationSchema
})

const first_name = useField('first_name')
const last_name = useField('last_name')
const birth_date = useField('birth_date')
const pesel = useField('pesel')
const school_level = useField('school_level')
const email = useField('email')
const password = useField('password')
const password_confirmation = useField('password_confirmation')
const isConfirmation1 = useField('isConfirmation1', false)
const isConfirmation2 = useField('isConfirmation2', false)

const q1_1 = useField('q1_1')
const q1_2 = useField('q1_2')
const q1_3 = useField('q1_3')
const q1_4 = useField('q1_4')
const q1_4_1 = useField('q1_4_1')
q1_1.setValue(0)
q1_2.setValue(0)
q1_3.setValue(0)
q1_4.setValue(0)
q1_4_1.setValue('')

const q2_1 = useField('q2_1')
const q2_2 = useField('q2_2')
const q2_3 = useField('q2_3')
const q2_4 = useField('q2_4')
const q2_4_1 = useField('q2_4_1')
q2_1.setValue(0)
q2_2.setValue(0)
q2_3.setValue(0)
q2_4.setValue(0)
q2_4_1.setValue('')

const dialog_rules1 = ref(false)
const dialog_rules2 = ref(false)
const dialog_rodo1 = ref(false)
const dialog_rodo2 = ref(false)
const passwordHidden = ref(true)
const noPesel = ref(false)
const schoolLevels = [
  'Kandydat/tka uczy się w 8 klasie szkoły podstawowej',
  'Kandydat/tka jest uczniem szkoły ponadpodstawowej',
  'Kandydat/tka jest uczniem szkoły za granicą',
  'Inna sytuacja szkolna kandydata/tki'
]

const changePeselSwitch = () => {
  if (noPesel.value) {
    pesel.resetField({
      value: ''
    })
    validationSchema.pesel = ''
  } else {
    validationSchema.pesel = 'pesel'
  }
}

const emit = defineEmits(['success'])

const submit = handleSubmit(async (values) => {
  if (values.q1_1 + values.q1_2 + values.q1_3 + values.q1_4 == 0) {
    setErrors({ q1_1: 'Należy wskazać przynajmniej jedno pole.' })
  }
  if (values.q2_1 + values.q2_2 + values.q2_3 + values.q2_4 == 0) {
    setErrors({ q2_1: 'Należy wskazać przynajmniej jedno pole.' })
  }
  if (values.q1_4 == 1 && !values.q1_4_1.trim()) {
    setErrors({ q1_4_1: 'Pole musi być wypełnione.' })
  }
  if (values.q2_4 == 1 && !values.q2_4_1.trim()) {
    setErrors({ q2_4_1: 'Pole musi być wypełnione.' })
  }

  if (Object.keys(errors.value).length > 0) {
    return
  }

  await axios
    .post(api.path('register'), values)
    .then((res) => {
      const toast = useToast()
      toast.success('Kandydat/tka został/a zarejestrowany/na w systemie')
      emit('success', res.data)
    })
    .catch((err) => {
      setErrors(err.response.data.errors)
    })
})
</script>

<style lang="scss">
.dp-custom-input {
  border: 0px solid black;
  border-bottom: 1px solid rgb(145, 142, 142);
  padding-top: 0px;
}
</style>
