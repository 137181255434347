import 'vuetify/styles'
import { createVuetify } from "vuetify";
import * as directives from "vuetify/directives";

const myCustomLightTheme = {
    dark: false,
    colors: {
        //primary: colors.cyan.darken3,
        //info: colors.cyan.darken3,
        primary: 0x1D3557,
        secondary: 0x457B9D,
        'color3': 0xA8DADC,
        'color4': 0xF1FAEE,
        'color5': 0xE63946,
        info: 0x457B9D,

    },
};

const vuetify = createVuetify({
    theme: {
        defaultTheme: "myCustomLightTheme",
        themes: {
            myCustomLightTheme,
        },
    },
    directives,
});

export default vuetify;